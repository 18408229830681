import { Transform, Type } from "class-transformer";
import "reflect-metadata";

export type LanguagesDAO = "en" | "en-NZ" | "de" | "ar";

export class LanguageDAO {
    en: string = "";
    "en-NZ": string = "";
    de: string = "";
    ar: string = "";
}

abstract class Status {
    createdAt: number = Date.now();

    /**
     * Time this object was last updated2
     */
    @Transform(() => {
        return Date.now();
    })
    /**
     * Time this object was last updated
     */
    updatedAt: number = Date.now();
}

/**
 * A user has no connetion to a parters/institution, etc. Those connections are made
 * via the roles. A user is simply used to set things like language, and name
 */
export class UserDAO extends Status {
    /**
     * id of partner
     */
    partnerId: string = ""; // Only partners or instituions can create users (except for jaipuan)
    institutionId: string = "";
    userId: string = "";
    name: string = "";
    email: string = "";
    autoRead: boolean = false;

    /**
     * The display language of the UI and language Amy uses to create feedback, etc.
     */
    public language: LanguagesDAO = "en";

    photoURL: string = "";
    stripeCustomerId: string = "";
    subscribed: "TRIAL" | "SAT-PURCHASED" = "TRIAL";
}

export type RoleTypesDAO =
    | "SYSADMIN"
    | "PARTNERADMIN"
    | "INSTITUTIONADMIN"
    | "CURRICULUMBUILDER"
    | "TEACHER"
    | "STUDENT";
export const RoleTypesArrayDAO: RoleTypesDAO[] = [
    "SYSADMIN",
    "PARTNERADMIN",
    "INSTITUTIONADMIN",
    "CURRICULUMBUILDER",
    "TEACHER",
    "STUDENT",
];

export class RoleDAO extends Status {
    roleId: string = "";
    roleType: RoleTypesDAO = "STUDENT";
    resourceId: string = "";
    userId: string = "";

    genId() {
        return `${this.resourceId}_${this.roleType}_${this.userId}`;
    }
}

export class CourseRoleDAO extends Status {
    id: string = "";
    courseId: string = "";
    memberId: string = "";
    role: RoleTypesDAO = "STUDENT";
    userId: string = "";
}

export class InstitutionRoleDAO extends Status {
    institutionRoleId: string = "";
    institutionId: string = "";
    memberId: string = "";
    role: RoleTypesDAO = "STUDENT";
    userId: string = "";
}

export class MemberDAO extends Status {
    memberId: string = "";
    name: string = "";
    institutionId: string = "";
    inviteCode: string = "";
    userId: string = "";
    email: string = "";
    reference: string = "";
}

export class InviteCodeDAO extends Status {
    inviteCodeId: string = "";
    roleType: RoleTypesDAO = "STUDENT";
    resourceId: string = "";
    inviteCode: string = "";
}

abstract class PartnerId extends Status {
    partnerId: string = "";
}
export class PartnerDAO extends PartnerId {
    name: string = ""; // This can be a name like "iQualify"
}

abstract class InstitutionId extends PartnerId {
    institutionId: string = "";
}
export class InstitutionDAO extends InstitutionId {
    name: string = ""; // This can be a name like "UC Canterbury"
}

abstract class CourseId extends InstitutionId {
    curriculumReferenceId: string = ""; // Reference to the curriculum entry point
    courseId: string = "";
}
export class CourseDAO extends CourseId {
    @Type(() => LanguageDAO)
    title: LanguageDAO = new LanguageDAO(); // This can be in any language we support
    @Type(() => LanguageDAO)
    subtitle: LanguageDAO = new LanguageDAO();
    source: "TreeOfMath" | "Curriculum" | "ABI" | "CUBI" | "ToM" = "Curriculum";
    type: "STANDARD" | "SELECTION" | "TESTING" | "DEVELOPMENT" = "STANDARD";
    progress: number = 0;
    numberOfDistractors: number = 4; // number of distractors: 0,1,2... Infinity
    alwaysNewAssignments: boolean = false; // this flag indicates that each time the user wants to start an assignment the user gets a new assignment. The default is, that the user gets the last unfinished assignment. This is currently only used for development and section courses
    studentLevel: "JUNIOR" | "SENIOR" = "SENIOR"; // indicates if junior or senior abi should be shown to user for this course
    showAnswers: boolean = false; // this will show on Amy what option is correct/incorrect before clicking. This is mostly used for testing
    showArchetypeId: boolean = false;
    showDebugInfo: boolean = false;
    showFeedback: boolean = true; // turn feedback on/off
    showHiddenAssignments: boolean = false;
    showHiddenSections: boolean = false;
    showPDNotes: boolean = false;
    shuffleRetry: boolean = false; // If turned off, the users get's the exact same options in the same order again after clicking an incorrect option
    minG: "0G" | "2G" | "3G" | "4G" | "5G" | "6G" = "3G"; // Defines the worst type of archetype that is allowed to be used. 0G means all archs can be used. 4G mean only archetype which have at least 4G == TESTED are allowed to be used
    teachingType: "AMY" | "AMY_KEEPING_ORIGINS" | "CLASSIC" = "AMY"; // this is currently done on a assignment level. This needs currently no implementation
    archived: boolean = false;
    experiment: string = "";
    description: string = "";
    amyUI: "v1" | "v2" = "v2";

    maxQuestions: number = 20; // Amount of exercises a student sees in an assignment. Overwritten by maxSetupQuestions if maxQuestions < maxSetupQuestions
    once: boolean = false;
    dynamicTeaching: boolean = true;
    maxSetupQuestions: number = 20; // Total amount of questions manually added to an assignment
    keepOriginals: boolean = true; //make sure original archetypes which got put into an assignment will be taught in any case
    maxDynamicArchetypeRepetition: number = 20;
    firstLast: boolean = false;
}

export class CourseSectionDAO extends CourseId {
    @Type(() => LanguageDAO)
    title: LanguageDAO = new LanguageDAO(); // This can be in any language we support
    @Type(() => LanguageDAO)
    subtitle: LanguageDAO = new LanguageDAO();
    courseSectionId: string = "";
    parentCourseSectionId: string = ""; // The Parent is needed to create a Tree/Explorer structure
    position: number = 0;
    hidden: boolean = false; // Should this section show up or not
    path: string = ""; // the path to find this node
    progress: number = 0;
}

abstract class CourseAssignmentId extends CourseId {
    courseAssignmentId: string = "";
}
export class CourseAssignmentDAO extends CourseAssignmentId {
    @Type(() => LanguageDAO)
    title: LanguageDAO = new LanguageDAO(); // This can be in any language we support
    @Type(() => LanguageDAO)
    subtitle: LanguageDAO = new LanguageDAO();
    parentCourseSectionId: string = ""; // Reference to the parent to draw a tree
    archetypeIds: string[] = [];
    teachingType: "AMY" | "AMY_KEEPING_ORIGINS" | "CLASSIC" = "AMY";
    maxQuestions: number = 20; // Amount of exercises a student sees in an assignment. Overwritten by maxSetupQuestions if maxQuestions < maxSetupQuestions
    once: boolean = false;
    position: number = 0;
    pedagogicalNotes?: {};
    hidden: boolean = false;
    path: string = ""; // the path to find this node
    progress: number = 0;

    keepOriginals: boolean = true;
    shuffleRetry: boolean = false;
    dynamicTeaching: boolean = true;
    maxSetupQuestions: number = 20; // Total amount of questions manually added to an assignment
    numberOfDistractors: number = 4;
    showFeedback: boolean = true;
    maxDynamicArchetypeRepetition: number = 20;
    firstLast: boolean = false;

    onceUseDefault: boolean = true;
    keepOriginalsUseDefault: boolean = true;
    shuffleRetryUseDefault: boolean = true;
    dynamicTeachingUseDefault: boolean = true;
    maxSetupQuestionsUseDefault: boolean = true;
    numberOfDistractorsUseDefault: boolean = true;
    showFeedbackUseDefault: boolean = true;
    maxQuestionsUseDefault: boolean = true;
    maxDynamicArchetypeRepetitionUseDefault: boolean = true;
    firstLastUseDefault: boolean = true;
}

abstract class StudentAssignmentId extends CourseAssignmentId {
    roleType: RoleTypesDAO = "PARTNERADMIN";
    userId: string = "";
    memberId: string = "";
    studentAssignmentId: string = "";
}

export class StudentAssignmentDAO extends StudentAssignmentId {
    archetypeIds: string[] = [];
    teachingType: "AMY" | "AMY_KEEPING_ORIGINS" | "CLASSIC" = "AMY";
    once: boolean = false;

    finished: boolean = false;
    progress: number = 0;
    exercises: MetaExerciseDAO[] = [];

    maxQuestions: number = 20; // Amount of exercises a student sees in an assignment. Overwritten by maxSetupQuestions if maxQuestions < maxSetupQuestions
    keepOriginals: boolean = true;
    shuffleRetry: boolean = false;
    dynamicTeaching: boolean = true;
    maxSetupQuestions: number = 20; // Total amount of questions manually added to an assignment
    numberOfDistractors: number = 4;
    showFeedback: boolean = true;
    maxDynamicArchetypeRepetition: number = 20;
    firstLast: boolean = false;
}

abstract class ExerciseId extends StudentAssignmentId {
    exerciseId: string = "";
    archetypeId: string = "";
}

export interface MetaExerciseDAO {
    exerciseId: string;
    finished: boolean;
    state: "STARTED" | "SKIPPED" | "DYNAMIC_SKIPPED" | "FINISHED" | "PENDING";

    exerciseType: "ROOT" | "DYNAMIC" | "TEST"; // Was the arch used to teach a new skill, test or root
    contentType: "DEFAULT" | "MATRIX";
    archetypeId: string;
    rootArchetypeId: string;
    title: LanguageDAO;
}

abstract class RowId extends ExerciseId {
    rowId: string = "";
}

type RowType = "INSTRUCTION" | "FEEDBACK" | "OPTION";
export abstract class RowDAO extends RowId {
    // since rows can be repeated when users do something wrong,
    // we need to keep track on what archetype row they made the mistake.
    // This does also allow for different users interfaces which group anything
    // belonging to the same archetypeRow together
    archetypeRowId: string = "";
    abstract rowType: RowType;
}

export class InstructionRowDAO extends RowDAO {
    rowType: RowType = "INSTRUCTION";
    text: string = "";
    texts: string[] = [];
    teachingText: string = "";
}

export class FeedbackRowDAO extends RowDAO {
    rowType: RowType = "FEEDBACK";
    text: string = "";
    feedbackType: "POSITIVE" | "NEGATIVE" | "NEUTRAL" = "NEUTRAL";
    archetypeOptionId: string = "";
}
export class OptionRowDAO extends RowDAO {
    correct: "YES" | "NO" | "UNKNOWN" = "UNKNOWN";
    rowType: RowType = "OPTION";
    options: OptionDAO[] = [];
}

export class OptionDAO {
    optionId: string = "";
    hint: "YES" | "NO" | "UNKNOWN" = "UNKNOWN";
    text: string = "";
    correct: "YES" | "NO" | "UNKNOWN" = "UNKNOWN";
}
