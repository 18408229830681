import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";

export function Footer({ color }: { color: CSSProperties["color"] }) {
    return (
        <footer style={{ padding: "100px 0", color: color }}>
            <Container>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={4}>
                        <h2>Follow Us</h2>
                        <Grid container spacing={2} justifyContent={"flex-start"}>
                            <Grid item>
                                <Link
                                    to="https://www.facebook.com/mytutoramy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Facebook style={{ color: color, fontSize: "25pt" }} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="https://twitter.com/mytutoramy" target="_blank" rel="noopener noreferrer">
                                    <Twitter style={{ color: color, fontSize: "25pt" }} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    to="https://www.instagram.com/mytutoramy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Instagram style={{ color: color, fontSize: "25pt" }} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    to="https://www.linkedin.com/company/amyapp"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <LinkedIn style={{ color: color, fontSize: "25pt" }} />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <h2>Contact Us</h2>
                        <a
                            style={{ color: color, textDecoration: "none", fontSize: "14pt" }}
                            href="mailto:hello@amy.app"
                        >
                            hello@amy.app
                        </a>
                    </Grid>
                </Grid>

                <p style={{ fontSize: "12pt", marginTop: "40px" }}>
                    <Link
                        to="/privacy"
                        style={{
                            marginRight: "20px",
                            color: color,
                            textDecoration: "none",
                            fontSize: "14pt",
                        }}
                    >
                        Privacy Policy
                    </Link>
                    |
                    <Link
                        to="/terms"
                        style={{
                            marginLeft: "20px",
                            marginRight: "20px",
                            color: color,
                            textDecoration: "none",
                            fontSize: "14pt",
                        }}
                    >
                        Terms of Use
                    </Link>
                    |
                    <button
                        type="button"
                        id="hs_show_banner_button"
                        style={{ background: "none", padding: 0, fontWeight: "inherit", border: "none" }}
                        onClick={() => {
                            var _hsp = ((window as any)._hsp = (window as any)._hsp || []);
                            _hsp.push(["showBanner"]);
                        }}
                    >
                        <Link
                            to=""
                            style={{
                                marginLeft: "20px",
                                fontSize: "14pt",
                                color: color,
                                textDecoration: "none",
                            }}
                        >
                            Cookie Settings
                        </Link>
                    </button>
                </p>
                <p>{`© Amy ${new Date().getFullYear() || "2021"}`}. All rights reserved.</p>
            </Container>
        </footer>
    );
}
