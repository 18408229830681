import {
    AppBar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemText,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { mutate } from "swr";
import { useWindowSize } from "usehooks-ts";
import { ReactComponent as DiscordLogo } from "./res/discordLogo.svg";
import headerLogo from "./res/headerLogo.svg";
import { getTrialTime, usePrice, useUser } from "./tools/amyHooks";
import { apiFetch } from "./tools/apiFetch";

export function AmyBar() {
    const theme = useTheme();
    const user = useUser();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const studyPage = location.pathname.includes("study");
    const [searchParams, setSearchParams] = useSearchParams();
    const [priceDetails, setPriceDetails] = useState(false);

    const trialTime = getTrialTime(user);

    const subscribed = user?.subscribed ?? "TRIAL";

    const query = searchParams.get("checkout");
    const open = query === "success";

    useEffect(() => {
        if (user && open && user.subscribed === "TRIAL") {
            const path = `/users/${user.userId}`;

            const interval = setInterval(() => {
                mutate({ url: path, path, method: "GET" });
            }, 500);

            return () => clearInterval(interval);
        }
    }, [open, user]);

    const locationData = usePrice();

    return (
        <>
            {/* <Promo user={user} stripePrice={locationData.stripePrice} /> */}
            <Dialog
                open={open}
                PaperProps={{
                    style: { borderRadius: "30px", width: 500, height: subscribed === "SAT-PURCHASED" ? 450 : 200 },
                }}
                slots={{
                    backdrop: subscribed === "SAT-PURCHASED" ? ConfettiBackdrop : null,
                }}
            >
                <DialogContent sx={{ display: "flex", alignItems: "center", padding: "15px" }}>
                    {subscribed === "SAT-PURCHASED" ? (
                        <Typography variant="h4" style={{ whiteSpace: "pre-line" }}>
                            {
                                "Yay! You are a Pro member now😎 \n\n Get ready to unlock your potential and ace the SATs! \n\n Let's get started!"
                            }
                        </Typography>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress size={50} />
                            <Typography variant="h6" marginTop="15px">
                                Please wait while we process your payment...
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{ padding: 0, justifyContent: "center" }}>
                    {subscribed === "SAT-PURCHASED" && (
                        <Button
                            onClick={() => {
                                searchParams.delete("checkout");
                                setSearchParams(searchParams);
                            }}
                        >
                            Close
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                open={priceDetails}
                PaperProps={{
                    style: { borderRadius: "30px", alignItems: "center", width: 370 },
                }}
            >
                <DialogTitle>
                    <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        Amy SAT Pro
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", padding: "15px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Price: {locationData.currencySymbol}
                            {locationData.price} {locationData.currencyCode}
                        </Typography>
                        <Typography variant="body2">This is a one-time payment.</Typography>
                    </Box>
                    <Typography variant="body1">Amy SAT Pro includes:</Typography>
                    <List>
                        <ListItemText>✨Lifetime access</ListItemText>
                        <ListItemText>✨Dynamic teaching</ListItemText>
                        <ListItemText>✨Feedback and much more</ListItemText>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ marginRight: "6px" }}
                        onClick={() => {
                            setPriceDetails(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: "white",
                        }}
                        onClick={async () => {
                            setLoading(true);
                            const res = await apiFetch<{ data: { url: string } }>({
                                path: `/stripe/checkout`,
                                data: { price: locationData.stripePrice },
                                method: "POST",
                            });
                            setLoading(false);
                            setPriceDetails(false);

                            window.open(`${res.data.url}`, "_self");
                        }}
                    >
                        Get SAT Pro
                    </Button>
                </DialogActions>
            </Dialog>
            <AppBar elevation={0} style={{ background: "none", zIndex: 200, position: "relative" }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Link to={user ? "/study" : "/"}>
                            <img src={headerLogo} alt="Logo" style={{ height: "25px" }} />
                        </Link>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
                        {studyPage ? (
                            <>
                                {subscribed === "TRIAL" && (
                                    <>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                marginRight: "10px",
                                                color: "white",
                                            }}
                                        >
                                            {trialTime > 0 ? (
                                                <>
                                                    Trial ends in <b>{trialTime}</b> {trialTime <= 1 ? "day" : "days"}
                                                </>
                                            ) : (
                                                <>Trial period has ended</>
                                            )}
                                        </Typography>
                                        <Button
                                            color="secondary"
                                            style={{
                                                marginRight: "10px",
                                                backgroundColor: "white",
                                                color: theme.palette.primary.main,
                                            }}
                                            onClick={() => {
                                                setPriceDetails(true);
                                            }}
                                        >
                                            Buy Amy
                                        </Button>
                                    </>
                                )}
                                <Button
                                    color="secondary"
                                    onClick={async () => {
                                        await getAuth().signOut();
                                        navigate("/");
                                    }}
                                >
                                    Logout
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    color="secondary"
                                    sx={{
                                        marginRight: "10px",
                                        "&:hover": {
                                            backgroundColor: "inherit",
                                            color: "white",
                                        },
                                    }}
                                    onClick={async () => {
                                        window.open(`https://discord.gg/ZknF4793Zr`, "_blank");
                                    }}
                                >
                                    <DiscordLogo fill="white" height="20px" style={{ marginRight: "10px" }} />
                                    Join Discord
                                </Button>
                                {user ? (
                                    <Button
                                        color="secondary"
                                        style={{
                                            backgroundColor: "white",
                                            color: theme.palette.primary.main,
                                        }}
                                        onClick={() => {
                                            navigate("/study");
                                        }}
                                    >
                                        Study
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                                navigate("/login");
                                            }}
                                        >
                                            Log in
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </Toolbar>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: 1000 }}
                        open={loading}
                        onClick={() => {
                            setLoading(false);
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Container>
            </AppBar>
            {/* We place this background here so it will render below any fullwidth banner elements for scrolling */}
            <div
                style={{
                    // width: `100vw`,
                    height: "64px",
                    backgroundSize: "cover",
                    background: "transparent",
                    position: "sticky",
                    marginTop: "-64px",
                    top: 0,
                    right: 0,
                    zIndex: 20,
                }}
            />
        </>
    );
}

function ConfettiBackdrop() {
    const { width, height } = useWindowSize();
    return (
        <Backdrop open={true}>
            <Confetti width={width} height={height} />
        </Backdrop>
    );
}
