import { plainToClass } from "class-transformer";
import { getAuth } from "firebase/auth";
import {
    CourseAssignmentDAO,
    CourseDAO,
    FeedbackRowDAO,
    InstructionRowDAO,
    OptionRowDAO,
    StudentAssignmentDAO,
} from "../Models";
import { apiFetch } from "./apiFetch";

export async function selectOption({
    studentAssignmentId,
    exerciseId,
    oRowId,
    optionId,
}: {
    studentAssignmentId: string;
    exerciseId: string;
    oRowId: string;
    optionId: string;
}) {
    const fa = getAuth();
    const userId = fa.currentUser?.uid;

    const path = `/users/${userId}/studentAssignments/${studentAssignmentId}/exercises/${exerciseId}/optionRows/${oRowId}`;
    const { data } = await apiFetch<{
        data: {
            status: number;
            correct: boolean;
            studentAssignment: any;
            courseAssignment: any;
            exercises: any[];
            rows: any[];
            course: any;
            message?: string;
        };
    }>({ path, data: { selectedOptionId: optionId }, method: "POST" });

    const studentAssignment = plainToClass(StudentAssignmentDAO, data.studentAssignment);
    const courseAssignment = plainToClass(CourseAssignmentDAO, data.courseAssignment);
    const course = plainToClass(CourseDAO, data.course);

    const exercises = studentAssignment.exercises;

    const rows: (OptionRowDAO | FeedbackRowDAO | InstructionRowDAO)[] = [];
    for (const row of data.rows) {
        if (row.rowType === "INSTRUCTION") {
            rows.push(plainToClass(InstructionRowDAO, row));
        }

        if (row.rowType === "FEEDBACK") {
            rows.push(plainToClass(FeedbackRowDAO, row));
        }

        if (row.rowType === "OPTION") {
            rows.push(plainToClass(OptionRowDAO, row));
        }
    }

    return { correct: data.correct, studentAssignment, courseAssignment, exercises, rows, course };
}
