import { Check, Close } from "@material-ui/icons";
import {
    Box,
    Button,
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { theme } from "../..";
import { AmyBar } from "../../AmyBar";
import { AmyThemeProvider } from "../../AmyThemeProvider";
import { Footer } from "../../components/Footer";
import companyEWC from "../../res/companyLogos/EWC_logo.svg";
import companyGESA from "../../res/companyLogos/GESA.svg";
import CompanyGSV from "../../res/companyLogos/GSV_logo.svg";
import CompanyEduTechAsia from "../../res/companyLogos/edutech_asia.svg";
import CompanyGess from "../../res/companyLogos/gess.svg";
import CompanySlingshot from "../../res/companyLogos/slingshot_logo.svg";
import CompanyUnesco from "../../res/companyLogos/unesco.svg";
import CompanyWharton from "../../res/companyLogos/wharton.svg";
import CompanyWorldEdCongress from "../../res/companyLogos/world_educations_congress.svg";
import amygradientLogo from "../../res/gradientLogo.svg";
import { usePrice } from "../../tools/amyHooks";

function CompareCheck() {
    return <Check style={{ color: "green" }} />;
}
function CompareCross() {
    return <Close style={{ color: "red" }} />;
}

export function Landing() {
    const navigate = useNavigate();
    const locationData = usePrice();

    return (
        <AmyThemeProvider theme={theme}>
            <div style={{ background: theme.header.background }}>
                <AmyBar />
                <Container maxWidth="xl">
                    <Grid container spacing={6}>
                        <Grid item xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography
                                color="secondary"
                                style={{
                                    // fontSize: "min(5vw, 5vh)",
                                    marginTop: "1em",
                                    // width: "80%",
                                    textAlign: "center",
                                    fontSize: "min(6vw,5em)",
                                    lineHeight: "1.3em",
                                    fontWeight: 600,
                                }}
                            >
                                Master the <span>SATs</span> with affordable personalized 1-on-1 tutoring!
                            </Typography>
                            <Typography
                                style={{
                                    marginBottom: "1em",
                                    textAlign: "center",
                                    fontSize: "min(3vw,2.5em)",
                                    lineHeight: "1.3em",
                                }}
                                color="secondary"
                            >
                                Trusted by 19,000 students
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    size="large"
                                    color="secondary"
                                    style={{
                                        backgroundColor: "white",
                                        color: theme.palette.primary.main,
                                        marginBottom: "5px",
                                    }}
                                    onClick={() => {
                                        navigate("/login");
                                    }}
                                >
                                    Study Free for a Week
                                </Button>
                                <Typography variant="body2" color="secondary">
                                    No credit card required
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} lg={10} margin="0 auto">
                            <div
                                style={{
                                    position: "relative",
                                    borderRadius: "8px",
                                    background: "rgba(255,255,255,0.3)",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            >
                                <video
                                    poster="/poster.png"
                                    autoPlay
                                    muted
                                    controls
                                    style={{
                                        width: "calc(100% - 2vw)",
                                        borderRadius: "8px",
                                        margin: "1vw",
                                    }}
                                >
                                    <source src="AmyDemo.webm" type='video/webm; codecs="vp8, vorbis"' />
                                    <source src="AmyDemo.mp4" type="video/mp4; " />
                                </video>
                                {/* <video
                                    poster="/poster.png"
                                    // autoPlay
                                    muted
                                    controls
                                    src="/AmyDemo.webm"
                                    style={{
                                        width: "calc(100% - 2vw)",
                                        borderRadius: "8px",
                                        margin: "1vw",
                                    }}
                                /> */}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} marginTop="5em">
                                    <Marquee pauseOnHover>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={CompanyWorldEdCongress}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="WorldEdCongress"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={CompanyEduTechAsia}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="EduTechAsia"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={CompanyGess}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="Gess"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={CompanyGSV}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="GSV"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={companyEWC}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="EWC"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={CompanySlingshot}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="Slingshot"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={CompanyUnesco}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="Unesco"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={companyGESA}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="GESA"
                                            />
                                        </Grid>
                                        <Grid item margin="0 30px">
                                            <img
                                                src={CompanyWharton}
                                                style={{ width: "100%", maxWidth: "150px" }}
                                                alt="Wharton"
                                            />
                                        </Grid>
                                    </Marquee>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container style={{ marginTop: "10px" }} spacing={6}>
                                <Grid item xs={12}>
                                    <Typography
                                        color="secondary"
                                        style={{
                                            fontSize: "max(3.5vw, 2em)",
                                        }}
                                    >
                                        Features
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            margin: "auto",
                                            overflow: "visible",
                                            maxWidth: "100%",
                                            minWidth: "80%",
                                            // background: "blue",
                                        }}
                                    >
                                        <div
                                            style={{
                                                // width: "fit-content",
                                                background: "rgba(255,255,255,0.3)",
                                                // margin: "20px",
                                                padding: "20px",
                                                borderRadius: "8px",
                                                maxWidth: "700px",
                                                overflowX: "auto",
                                            }}
                                        >
                                            <Table sx={{ background: "white", borderRadius: "8px" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell
                                                            align="center"
                                                            width="30%"
                                                            style={{ color: "black" }}
                                                        >
                                                            <img
                                                                src={amygradientLogo}
                                                                height="30px"
                                                                style={{ marginTop: "10px" }}
                                                                alt="Amy logo"
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" width="30%">
                                                            <img
                                                                src="https://www.svgrepo.com/show/353965/khan-academy-icon.svg"
                                                                height="25px"
                                                                style={{ marginTop: "10px" }}
                                                                alt="KhanLogo"
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" width="30%">
                                                            Tutor
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <b>Costs</b>
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{ fontWeight: 600, fontSize: "1.5em" }}
                                                        >
                                                            {locationData.currencySymbol}
                                                            {locationData.price} {locationData.currencyCode}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{ fontWeight: 600, fontSize: "1.5em" }}
                                                        >
                                                            {locationData.currencySymbol}0 {locationData.currencyCode}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{ fontWeight: 600, fontSize: "1.5em" }}
                                                        >
                                                            {locationData.currencySymbol}
                                                            {locationData.tutorPrice} {locationData.currencyCode}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <b>Feedback</b>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCross />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <b>Affordable</b>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCross />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <b>24 / 7</b>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCross />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <b>Tutoring</b>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCross />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CompareCheck />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <b>Quality</b>
                                                        </TableCell>
                                                        <TableCell align="center">😁</TableCell>
                                                        <TableCell align="center">☹️</TableCell>
                                                        <TableCell align="center">😀</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="center">
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    navigate("/login");
                                                                }}
                                                            >
                                                                Start Free Trial
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="center"></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        color="secondary"
                                        style={{
                                            fontSize: "max(3.5vw, 2em)",
                                        }}
                                    >
                                        What our users say
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Marquee pauseOnHover>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "Thanks for including my daughter. She is really buzzing about the
                                                    app on the way home - you might have revived her enthusiasm for
                                                    maths!"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Rachael Koelmeyer (Parent)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "My students are actively asking to continue their work with Amy"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>
                                                Dylan Ragi (Teacher)
                                                <br />
                                                Emirates International School Dubai
                                            </p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "It was very fun to use and I would like to use it for my learning
                                                    more. It was absolutely amazing and if I got the answer wrong it
                                                    would let me try again. Sometimes it was hard which was very fun"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Hazel (Student)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "The site helps me understand more about fractions. The questions
                                                    are easy and simple to do. I liked how the questions were easy to do
                                                    and not so hard. There are answers that get harder each time"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Natui (Student)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "It was nice, I had fun, I love it and I would do this again because
                                                    it is pretty easy for me in year 5/6. But it was kind of hard before
                                                    but I got used to all of it and I will give it a 10/10"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Emma (Student)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "I like AMY because it actually tries to challenge you. This can
                                                    help increase the use of brain cells we have. It also helps you when
                                                    you get a question wrong. I'll give it an 8/10"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Austin (Student)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "I really like the site i would rate it out of 9.1/10 because when
                                                    you get it wrong you have to go back to what you did before, but its
                                                    actually easy"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Sam (Student)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "I give this a 10/10 rating, I liked that it had different stages
                                                    like the understanding fractions, addition and subtraction,
                                                    multiplication, simplification, understanding fractions(pt 2) and
                                                    the understanding fractions(pt 3)"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Ethan (Student)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "The content of the website really helps kids to understand
                                                    fractions, and it's very cool that when you get a question wrong
                                                    they tell you why you were wrong and they explain how you actually
                                                    do the equation. The website AMY is a site that I would go on again"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Kirsten (Student)</p>
                                        </Grid>
                                        <Grid item margin="0 30px" maxWidth="400px">
                                            <p style={{ color: "white" }}>
                                                <em>
                                                    "I found the app really fun, I liked how if you put in the wrong
                                                    answer then it would let you try again and not just skip to the next
                                                    question. It was also really easy to use, so I rate it 10/10"
                                                </em>
                                            </p>
                                            <p style={{ color: "white" }}>Luciana (Student)</p>
                                        </Grid>
                                    </Marquee>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Footer color="white" />
            </div>
        </AmyThemeProvider>
    );
}
