import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import useSWR from "swr";
import {
    CourseAssignmentDAO,
    CourseDAO,
    FeedbackRowDAO,
    InstructionRowDAO,
    MetaExerciseDAO,
    OptionRowDAO,
    StudentAssignmentDAO,
    UserDAO,
} from "../Models";
import { apiFetch } from "./apiFetch";
import { getStudentAssignment } from "./getStudentAssignment";
import { selectOption } from "./selectOption";
import { sqlQuery } from "./sqlQuery";

export function useStudentAssignment(studentAssignmentId: string) {
    const [studentAssignment, setStudentAssignment] = useState<{
        course: CourseDAO;
        studentAssignment: StudentAssignmentDAO;
        courseAssignment: CourseAssignmentDAO;
        exercises: MetaExerciseDAO[];
        rows: (OptionRowDAO | FeedbackRowDAO | InstructionRowDAO)[];
        loading: boolean;
    }>({
        course: null,
        studentAssignment: null,
        courseAssignment: null,
        exercises: [],
        rows: [],
        loading: true,
    });

    const [activeExerciseId, setActiveExerciseId] = useState<"DONE" | "" | string>("");

    const selectOptionLocal = (studentAssignmentId: string, exerciseId: string, oRowId: string, optionId: string) => {
        setStudentAssignment((old) => {
            return {
                ...old,
                loading: true,
            };
        });
        selectOption({
            exerciseId,
            optionId,
            oRowId,
            studentAssignmentId,
        })
            .then(({ studentAssignment, courseAssignment, exercises, rows, course }) => {
                setStudentAssignment({
                    course,
                    studentAssignment,
                    courseAssignment,
                    exercises,
                    rows,
                    loading: false,
                });
            })
            .catch((e: Error) => {
                setStudentAssignment((old) => {
                    return {
                        ...old,
                        loading: false,
                    };
                });
                console.error(
                    `Connection error1, it looks like there was a glitch in your internet connection, please try again. (${e.message})`,
                    e.message,
                );
            });
    };

    useEffect(() => {
        const userId = getAuth().currentUser.uid;
        getStudentAssignment({ userId, studentAssignmentId })
            .then((e) => {
                const { studentAssignment, courseAssignment, exercises, rows, course } = e;
                setStudentAssignment({
                    course,
                    studentAssignment,
                    courseAssignment,
                    exercises,
                    rows,
                    loading: false,
                });
            })
            .catch((e: Error) => {
                // we set loading to false, so users can click buttons again
                setStudentAssignment((old) => {
                    return {
                        ...old,
                        loading: false,
                    };
                });
                console.error(
                    `Connection error2, it looks like there was a glitch in your internet connection, please try again. (${e.message})`,
                    e.message,
                );
                // window.alert(
                //     `Connection error, it looks like there was a glitch in your internet connection, please try again. (${e.message})`,
                // );
            });
    }, []);

    return {
        selectOption: selectOptionLocal,
        ...studentAssignment,
        activeExerciseId,
        setActiveExerciseId,
    };
}

export function useUser(): UserDAO | null {
    const userId = getAuth().currentUser?.uid;
    const path = `/users/${userId}`;
    const { data, isLoading, error } = useSWR<{ status: number; data: { user: UserDAO } }>(
        { url: path, path, method: "GET" },
        apiFetch,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // Never retry on 404.

                // Only retry up to 10 times.
                if (retryCount >= 30) return;

                // Retry after 5 seconds.
                setTimeout(() => revalidate({ retryCount }), 500);
            },
            keepPreviousData: true,
        },
    );

    if (isLoading || error) {
        return null;
    }

    return data.data.user;
}

export function getTrialTime(user: UserDAO) {
    let trialTime = 0;
    if (user) {
        const trialEndDate = new Date(user.createdAt + 1000 * 60 * 60 * 24 * 7);
        const remainingTime = Number(trialEndDate) - Number(new Date());
        if (remainingTime > 0) {
            trialTime = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        }
    }
    return trialTime;
}

export function usePrice() {
    const [data, setData] = useState<{
        location: string;
        currencySymbol: string;
        currencyCode: string;
        price: string;
        stripePrice: string;
        tutorPrice: string;
    }>({
        location: "ALL",
        currencySymbol: "$",
        currencyCode: "NZD",
        price: "20",
        stripePrice: "price_1OOheOLeLcLBsAHNxVU39MJ1",
        tutorPrice: "200",
    });

    useEffect(() => {
        fetch("https://sat.amy.app/location.json")
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setData(data);
                }
            })
            .catch((error) => console.error("Error:", error));
    }, []);

    return data;
}

export function useQuery<T>(query: string, onError?: (error: any) => void) {
    const [data, setData] = useState<T[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        sqlQuery<T>(query)
            .then((e) => {
                setData(e.data.rows);
                setLoading(false);
            })
            .catch((e) => {
                onError && onError(e);
                setData([]);
                setLoading(false);
            });
    }, [query]);

    return { data, loading };
}

/**
 * This is a special tagged template literal that's only use is to
 * make SQL highlighting possible with this vs-code plugin
 * https://marketplace.visualstudio.com/items?itemName=frigus02.vscode-sql-tagged-template-literals
 * @param strings
 * @param values
 * @returns
 */
export function sql(strings: TemplateStringsArray, ...values: string[]) {
    return strings
        .map((str, i) => str + (values[i] ?? ""))
        .join("")
        .trim();
}
