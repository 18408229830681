import { getAuth, onAuthStateChanged } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import { Suspense, lazy, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import { Landing } from "./Pages/Landing/Landing";
import { CenteredProgess } from "./components/CenteredProgress";

const SATNavigationCourse = lazy(() => import("./Pages/SAT/SATNavigationCourse"));
const LoginPage = lazy(() => import("./Pages/Landing/LoginPage"));
const SATSection = lazy(() => import("./Pages/SAT/SATSection"));

function TrackPageViews() {
    const location = useLocation();

    useEffect(() => {
        mixpanel.track_pageview();
    }, [location]);

    return null;
}

export function App() {
    console.log("firebase.auth().currentUser", getAuth().currentUser);
    const [loginState, setLoginState] = useState<"UNKNOWN" | "YES" | "NO">("UNKNOWN");

    useEffect(() => {
        return onAuthStateChanged(getAuth(), (firebaseUser) => {
            console.log("observeAuthState", firebaseUser);
            setLoginState(firebaseUser ? "YES" : "NO");
            if (firebaseUser) {
                mixpanel.identify(firebaseUser.uid);
            }
        });
    }, []);

    return (
        <Router>
            <TrackPageViews />
            <Routes>
                <Route
                    path="/login"
                    element={
                        <Suspense fallback={<CenteredProgess color="primary" />}>
                            <LoginPage />
                        </Suspense>
                    }
                />

                <Route
                    path="/study/:courseId/section/:courseSectionId/assignment/:courseAssignmentId"
                    element={
                        <>
                            {loginState === "UNKNOWN" && <></>}
                            {loginState === "YES" && (
                                <Suspense fallback={<CenteredProgess color="primary" />}>
                                    <SATSection />
                                </Suspense>
                            )}
                        </>
                    }
                />
                <Route
                    path="/study"
                    element={
                        <>
                            {loginState === "UNKNOWN" && <></>}
                            {loginState === "YES" && (
                                <Suspense fallback={<CenteredProgess color="primary" />}>
                                    <SATNavigationCourse />
                                </Suspense>
                            )}
                        </>
                    }
                />
                <Route path="/" element={<>{loginState === "UNKNOWN" ? <></> : <Landing />}</>} />
            </Routes>
        </Router>
    );
}
