import { apiFetch } from "./apiFetch";

/**
 * Query all data a user is allowed to read
 * @param query SQL query
 */
export async function sqlQuery<T>(query: string) {
    return apiFetch<{
        status: number;
        data: {
            rows: T[];
            rowCount: number;
            fields: string[];
        };
    }>({ path: "/analytics/sql", data: { query } }).then((e) => {
        if (e.status / 100 === 4) {
            return Promise.reject(e);
        }

        return e;
    });
}
