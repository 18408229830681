import { initializeApp } from "firebase/app";
import mixpanel from "mixpanel-browser";
import * as ReactDOMClient from "react-dom/client";
import { AmyThemeProvider } from "./AmyThemeProvider";
import { App } from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import headerLogo from "./res/headerLogo.svg";
import landingLogo from "./res/landingLogo.svg";

export const amyRed = "rgb(255, 49, 98)"; // #ff3162
export const amyBlue = "rgb(108, 148, 247)"; // #6c94f7
export const amyNavy = "rgb(23, 27, 45)"; // #171b2d

declare global {
    interface Window {
        recaptchaVerifier: any;
        confirmationResult: any;
    }
}

initializeApp({
    apiKey: "AIzaSyC9ttADyEm-cfPb__joYv3MgBGjWOkxwMc",
    authDomain: "sat.amy.app",
    databaseURL: "https://amy--app-eu.europe-west1.firebasedatabase.app",
    projectId: "amy--app",
    storageBucket: "amy--app.appspot.com",
    messagingSenderId: "344535742053",
    appId: "1:344535742053:web:a3298943d3d6171f0af88c",
});

mixpanel.init(process.env.REACT_APP_USE_EMULATOR === "true" ? "test" : "38a5bec6c7744cae82098d8361b88126", {
    debug: false,
    track_pageview: true,
    persistence: "localStorage",
    ignore_dnt: true,
    api_host: "https://mp.amy.app",
});

//@ts-ignore
export const theme: any = {
    header: {
        logo: headerLogo,
        background: `linear-gradient(90deg, rgba(75,120,204,1) 20%, rgba(213,118,202,1) 80%)`,
        useDarkText: false,
        hide: false,
    },
    login: {
        background: amyNavy,
        // hidePoweredBy: false,
        useDarkText: false,
        logo: landingLogo,
        hidePoweredBy: true,
    },

    stickyInstruction: true,
    stickyOption: true,
    bigFont: false,
    row: {
        padding: "14px 15px",
    },

    dashboard: {
        hide: false,
    },
    backdrop: {
        background: `linear-gradient(90deg, rgba(75,120,204,1) 20%, rgba(213,118,202,1) 80%)`,
        fadeBackground: true,
        useDarkText: false,
    },
    shape: {
        borderRadius: 5,
    },
    palette: {
        primary: {
            main: amyBlue,
        },
        error: { main: "#ff6b4d" },
        info: {
            main: amyNavy,
        },
        secondary: {
            main: "#FFFFFF",
        },
        success: {
            main: "#45be4b",
        },
        divider: "rgba(0,0,0,0.4)",
    },
    typography: {
        fontFamily: ["Nunito Sans", "Arial"].join(","),

        h1: {
            fontSize: 50,
            fontWeight: 800,
        },
        h2: {},
        h3: {},
        h4: {},
        h5: {},
        h6: {},
        subtitle1: {},
        subtitle2: {
            fontSize: "10pt",
            letterSpacing: "1.5px",
            fontWeight: 600,
            opacity: 0.7,
        },
        body1: {},
        body2: {},
        caption: {},
    },

    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: "bold",
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: "black",
                    background: "white",
                    border: "1px #9a9a9a solid",
                    fontSize: "15px",
                    fontFamily: "Nunito Sans,Arial",
                },
                arrow: {
                    color: "#9a9a9a",
                },
            },
        },

        MuiGrid: {
            defaultProps: {
                spacing: 1,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
            },
        },

        MuiFormControl: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiCircularProgress: {
            defaultProps: {
                thickness: 1.5,
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: "30px 10px",
                    borderRadius: "10px",
                    borderColor: "#9a9a9a",
                    margin: "0px",
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                outlinedPrimary: {
                    border: "1px solid #171B2D",
                    color: "#171B2D",
                    padding: "3px 10px",
                    "&:hover": {
                        backgroundColor: "#171B2D",
                        border: "1px solid #171B2D",
                        color: "#FFFFFF",
                    },
                },
                outlinedSecondary: {
                    border: "2px solid #FFFFFF",
                    color: "#FFFFFF",
                    "&:hover": {
                        border: "2px solid #FFFFFF",

                        backgroundColor: "#FFFFFF",
                        color: "#171B2D",
                    },
                },
                outlined: {
                    padding: "5px 25px",
                    borderRadius: "30px",
                    border: "2px solid #171B2D",
                    color: "#171B2D",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "#171B2D",
                        color: "#FFFFFF",
                    },
                },
                text: {
                    padding: "6px 30px",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: "20px",
                },
            },
        },
    },
};

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOMClient.createRoot(container);

root.render(
    <AmyThemeProvider theme={theme}>
        <App />
    </AmyThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
