import { plainToClass } from "class-transformer";
import {
    CourseAssignmentDAO,
    CourseDAO,
    FeedbackRowDAO,
    InstructionRowDAO,
    OptionRowDAO,
    StudentAssignmentDAO,
} from "../Models";
import { apiFetch } from "./apiFetch";

/**
 * @param __namedParameters The names parameter object
 * @param userId
 * @param studentAssignmentId The ID of an assignment. This ID is provided by amy.app
 */
export async function getStudentAssignment({
    userId,
    studentAssignmentId,
}: {
    userId: string;
    studentAssignmentId: string;
}) {
    const path = `/users/${userId}/studentAssignments/${studentAssignmentId}`;
    const { data } = await apiFetch<{
        data: {
            status: number;
            studentAssignment: any;
            courseAssignment: any;
            exercises: any[];
            rows: any[];
            course: any;

            message?: string;
        };
    }>({ path, method: "GET" });

    const studentAssignment = plainToClass(StudentAssignmentDAO, data.studentAssignment);
    const courseAssignment = plainToClass(CourseAssignmentDAO, data.courseAssignment);
    const course = plainToClass(CourseDAO, data.course);

    const exercises = studentAssignment.exercises;

    const rows: (OptionRowDAO | FeedbackRowDAO | InstructionRowDAO)[] = [];
    for (const row of data.rows) {
        if (row.rowType === "INSTRUCTION") {
            rows.push(plainToClass(InstructionRowDAO, row));
        }

        if (row.rowType === "FEEDBACK") {
            rows.push(plainToClass(FeedbackRowDAO, row));
        }

        if (row.rowType === "OPTION") {
            rows.push(plainToClass(OptionRowDAO, row));
        }
    }

    return { studentAssignment, courseAssignment, exercises, rows, course };
}
