import "@fontsource/nunito-sans";
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { DeprecatedThemeOptions, Theme } from "@mui/material/styles";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import React from "react";
declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export interface CustomTheme {
    header?: {
        background?: React.CSSProperties["background"];
        useDarkText?: boolean;
        logo?: string;
        hide?: boolean;
    };
    dashboard?: {
        hide?: boolean;
    };
    backdrop?: {
        background?: React.CSSProperties["background"];
        fadeBackground?: boolean;
        useDarkText?: boolean;
    };
    login?: {
        logo?: string;
        background?: React.CSSProperties["background"];
        hidePoweredBy?: boolean;
        useDarkText?: boolean;
    };
    row?: {
        border?: React.CSSProperties["border"];
        borderLeft?: React.CSSProperties["borderLeft"];
        borderRight?: React.CSSProperties["borderRight"];
        padding?: React.CSSProperties["padding"];
    };
    instructionRow?: {
        backgroundColor?: React.CSSProperties["color"];
    };
    optionRow?: {
        marginLeft?: React.CSSProperties["marginLeft"];
        correctBackgroundColor?: React.CSSProperties["color"];
        incorrectBackgroundColor?: React.CSSProperties["color"];
        unknownBackgroundColor?: React.CSSProperties["color"];
    };
    feedbackRow?: {
        positiveBackgroundColor?: React.CSSProperties["color"];
        negativeBackgroundColor?: React.CSSProperties["color"];
        neutralBackgroundColor?: React.CSSProperties["color"];
    };
    stickyInstruction?: boolean;
    stickyOption?: boolean;
    bigFont?: boolean;
    status?: {
        danger: React.CSSProperties["color"];
    };
    colors?: {
        primary?: React.CSSProperties["color"];
        secondary?: React.CSSProperties["color"];
    };
}

// Model augmentation
// https://material-ui.com/customization/palette/
declare module "@mui/material/styles/createTheme" {
    interface Theme {
        header?: {
            background?: React.CSSProperties["background"];
            useDarkText?: boolean;
            logo?: string;
            hide?: boolean;
        };
        dashboard?: {
            hide?: boolean;
        };
        backdrop?: {
            background?: React.CSSProperties["background"];
            fadeBackground?: boolean;
            useDarkText?: boolean;
        };
        login?: {
            logo?: string;
            background?: React.CSSProperties["background"];
            hidePoweredBy?: boolean;
            useDarkText?: boolean;
        };
        row?: {
            border?: React.CSSProperties["border"];
            borderLeft?: React.CSSProperties["borderLeft"];
            borderRight?: React.CSSProperties["borderRight"];
            padding?: React.CSSProperties["padding"];
        };
        instructionRow?: {
            backgroundColor?: React.CSSProperties["color"];
        };
        optionRow?: {
            marginLeft?: React.CSSProperties["marginLeft"];
            correctBackgroundColor?: React.CSSProperties["color"];
            incorrectBackgroundColor?: React.CSSProperties["color"];
            unknownBackgroundColor?: React.CSSProperties["color"];
        };
        feedbackRow?: {
            positiveBackgroundColor?: React.CSSProperties["color"];
            negativeBackgroundColor?: React.CSSProperties["color"];
            neutralBackgroundColor?: React.CSSProperties["color"];
        };
        stickyInstruction?: boolean;
        bigFont?: boolean;
        stickyOption?: boolean;
        status?: {
            danger: React.CSSProperties["color"];
        };
        colors?: {
            primary?: React.CSSProperties["color"];
            secondary?: React.CSSProperties["color"];
        };
    }

    interface DeprecatedThemeOptions extends CustomTheme {}
}

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        neutral?: Palette["primary"];
    }
    interface PaletteOptions {
        neutral?: PaletteOptions["primary"];
    }
}

// Theme colors can be found here: https://material-ui.com/customization/color/#color
// The theme elements can found be here: https://material-ui.com/customization/palette/

export const amyRed = "rgb(255, 49, 98)"; // #ff3162
export const amyBlue = "rgb(108, 148, 247)"; // #6c94f7
export const amyNavy = "rgb(23, 27, 45)"; // #171b2d
const oldThemePart1: CustomTheme = {
    dashboard: {
        hide: false,
    },
    login: {
        background: amyNavy,
        hidePoweredBy: false,
        useDarkText: false,
    },
    header: {
        background: `linear-gradient(90deg, rgb(33, 37, 55) 0%, rgb(118, 158, 257) 45%, rgb(118, 158, 257) 55%, rgb(255, 59, 108) 100%)`,
        useDarkText: false,
        hide: false,
    },
    backdrop: {
        background: `linear-gradient(90deg, ${amyNavy} 0%, ${amyBlue} 45%, ${amyBlue} 55%, 75%, ${amyRed} 100%)`,
        fadeBackground: true,
        useDarkText: false,
    },
    row: {
        padding: "14px 15px",
    },
    instructionRow: {
        backgroundColor: "#8cabf7",
    },
    feedbackRow: {
        neutralBackgroundColor: "#8cabf7",
        positiveBackgroundColor: "#8cabf7",
        negativeBackgroundColor: "#8cabf7",
    },
    optionRow: {
        unknownBackgroundColor: "#E5E5E5",
        correctBackgroundColor: "#93c94d",
        incorrectBackgroundColor: "#ff6186",
    },
    stickyInstruction: true,
    stickyOption: true,
    bigFont: false,
    status: {
        danger: "#ff6186",
    },
};
// deprecatedThemOptions has been added by MUI codemods migration tool,  when migrated from v4 to v5
const oldThemePart2: DeprecatedThemeOptions = {
    palette: {
        primary: {
            light: "#e2e9fe",
            main: amyBlue,
            dark: "#4076fa",
        },
        secondary: {
            main: "#FFFFFF",
        },
        divider: "rgba(0,0,0,0.4)",
    },

    shape: {
        borderRadius: 5,
    },

    props: {
        MuiGrid: {
            spacing: 1,
        },
        MuiTextField: {
            variant: "outlined",
        },
        MuiButton: {
            variant: "outlined",
        },

        MuiFormControl: {
            variant: "outlined",
        },
        MuiCircularProgress: {
            thickness: 1.5,
        },
    },

    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: "bold",
            },
        },
        MuiCard: {
            root: {
                padding: "30px 10px",
                borderRadius: "10px",
            },
        },
        MuiDialog: {
            paper: {
                padding: "20px",
            },
        },
        MuiButton: {
            outlinedPrimary: {
                border: "1px solid #171B2D",
                color: "#171B2D",
                "&:hover": {
                    backgroundColor: "#171B2D",
                    color: "#FFFFFF",
                },
            },
            outlinedSecondary: {
                border: "1px solid #FFFFFF",
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#171B2D",
                },
            },
            outlined: {
                padding: "5px 25px",
                borderRadius: "30px",
                border: "1px solid #171B2D",
                color: "#171B2D",
                textTransform: "none",
                "&:hover": {
                    backgroundColor: "#171B2D",
                    color: "#FFFFFF",
                },
            },
            text: {
                padding: "6px 30px",
            },
        },
    },
    typography: {
        fontFamily: ["Nunito Sans", "Arial"].join(","),

        h1: {
            fontSize: 50,
            fontWeight: 800,
        },
        h2: {},
        h3: {},
        h4: {},
        h5: {},
        h6: {},
        subtitle1: {},
        subtitle2: {},
        body1: {},
        body2: {},
        caption: {},
    },
};

export function createAmyTheme(theme: CustomTheme) {
    const merged = mergeDeep(oldThemePart2, oldThemePart2, theme);

    // overwrite primary colors
    if (theme?.colors?.primary) {
        merged.palette.primary.main = theme?.colors?.primary;
    }
    // overwrite primary colors
    if (theme?.colors?.secondary) {
        merged.palette.secondary.main = theme?.colors?.secondary;
    }

    let mergedTheme = createTheme(merged);

    // if bigFont, make font big globally
    if (theme.bigFont) {
        mergedTheme.components = {
            MuiCssBaseline: {
                styleOverrides: {
                    // MUI typography elements use REMs, so you can scale the global
                    // font size by setting the font-size on the <html> element.
                    html: {
                        fontSize: 22,
                    },
                },
            },
        };
    }
    return mergedTheme;
}

export function AmyThemeProvider({ children, theme }: { children?: React.ReactNode; theme?: Theme }) {
    if (theme) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={createTheme(mergeDeep(oldThemePart1, oldThemePart2, theme))}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createTheme(mergeDeep(oldThemePart1, oldThemePart2))}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}
